<template>
  <v-card v-if="recipe">
    <v-alert type="warning" v-if="recipe.notPublished">This recipe is not published and not visible on live site.</v-alert>
    <v-img
      :src="recipe.imgUrl"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      height="400px"
    >
      <span class="recipe-rating white--text float-left font-xs" v-if="recipe.avgRating && recipe.avgRating.count > 0">
        Rating:<br/>
        <v-rating v-model="recipe.avgRating.value" half-increments readonly dense size=18 full-icon="fas fa-star" half-icon="fas fa-star-half-alt" empty-icon="far fa-star" background-color="orange lighten-3" color="orange" class="d-inline-block" />
        <span class="d-inline-block">({{recipe.avgRating.count}})</span>
      </span>
      <span class="recipe-source white--text float-right font-xs" v-if="sourceInfo">
        <i class="fas fa-chevron-right"/>
        See original recipe at:
        <a class="white--text" :href="recipe.url" target="_blank">{{sourceInfo.name}}</a>
      </span>
      <v-card-title class="display-2" v-text="recipe.name"></v-card-title>
    </v-img>

    <v-tabs grow color="primary" show-arrows class="fixed-tabs-bar" v-model="selectedTab">
      <v-tab key="tab-summary" v-scroll-to="'#tab-summary'">
        Summary
      </v-tab>
      <v-tab key="tab-ingredients" v-scroll-to="'#tab-ingredients'">
        Ingredients
      </v-tab>
      <v-tab v-if="showSimilar" key="tab-similar" v-scroll-to="'#tab-similar'">
        Similar
      </v-tab>
      <v-tab v-if="showNutrients" key="tab-nutrients" v-scroll-to="'#tab-nutrients'">
        Nutrients
      </v-tab>
    </v-tabs>

<!-- 
      <v-row>
        <v-col cols="6">
        <div class="text-hint">Total duration:</div>
        <div>{{recipe.totalTime | duration }}</div>
      </div>
      <div v-if="recipe && recipe.prepTime">
        <div class="text-hint">Preparation time:</div>
        <div>{{recipe.prepTime | duration }}</div>
      </div>
      <div v-if="recipe && recipe.cookTime">
        <div class="text-hint">Cook time:</div>
        <div>{{recipe.cookTime | duration }}</div>
      </div>
        </v-col>
        <v-col cols="6">
          <div v-if="recipe && recipe.avgRating && recipe.avgRating.count > 0">
            <span class="text-hint">Rating:</span>
            <v-rating
              v-model="recipe.avgRating.value"
              half-increments
              readonly
              dense
              size=18
              full-icon="fas fa-star"
              half-icon="fas fa-star-half-alt"
              empty-icon="far fa-star"
              background-color="orange lighten-3"
              color="orange"
            ></v-rating> 
            <span>({{recipe.avgRating.count}})</span>
          </div>          
        </v-col>
      </v-row> -->
 <!--
    <v-card-actions v-if="recipe && recipe.avgRating && recipe.avgRating.count > 0">
      <v-spacer></v-spacer>
      <span>Rating:</span>
      <v-rating
        v-model="recipe.avgRating.value"
        half-increments
        readonly
        dense
        size=18
        full-icon="fas fa-star"
        half-icon="fas fa-star-half-alt"
        empty-icon="far fa-star"
        background-color="orange lighten-3"
        color="orange"
      ></v-rating> 
      <span>({{recipe.avgRating.count}})</span>
    </v-card-actions>
 -->
    <div id="tab-summary" class="tab-content" v-intersect="tabInterceptorConfig">
      <v-card-text>
        <div class="text--primary"><a name="summary">&nbsp;</a>
          {{recipe.description | striphtml }}
        </div>
        <div class="text--primary mt-4">
          <v-btn color="primary" dark target="_blank" :href="recipe.url">Read full recipe &raquo;</v-btn>
          <!-- <v-btn v-if="sourceInfo" color="primary" dark target="_blank" :href="recipe.url">Read full recipe on {{sourceInfo.name}} &raquo;</v-btn> -->
          <div class="cursive mt-2 ml-4 d-flex ">
            <img src="/images/arrow.png" class="flex-shrink-1 mr-4"/> 
            <span class="flex-grow-1 align-self-center"> 
              <span v-if="sourceInfo">continue reading on {{sourceInfo.name}}</span>
              for more instructions, background stories, photos and/or preparation videos.
            </span>
          </div>
  <!--         <div class="cursive mt-2 ml-4 d-flex">
            <img src="/images/arrow.png" class="float-left mr-4"/> 
            <span class="pt-1 d-block"> for more instructions, background stories and possibly preparation videos.</span>
          </div> -->
        </div>
      </v-card-text>

      <SourceInfo :source="sourceInfo" :url="recipe.url" />
    
      <v-divider></v-divider>
      <!-- Nutrient summary -->
        <NutrientSummary :model="nutrientData"></NutrientSummary>
      <v-card-text v-if="nutrientData">
        <div class="text--primary">
          <v-dialog width="310">
            <template v-slot:activator="{ on }">
              <v-btn small color="primary" outlined v-on="on">
                Show nutrition facts
              </v-btn>
            </template>
            <v-card>
              <NutrientLabel :summary="nutrientData.summary"></NutrientLabel>
            </v-card>
          </v-dialog>
          <v-btn small color="primary" text href="#nutrients">
            Detailed breakdown
          </v-btn>
        </div>
      </v-card-text>
    </div>
    <v-divider></v-divider>

    <!-- Ingredients -->
    <div id="tab-ingredients" class="tab-content" v-intersect="tabInterceptorConfig">
      <v-card-title class="headline">Ingredients</v-card-title>
      <v-card-text if="recipe && nutrientData && nutrientData.summary.servings">
        {{ recipe.ingredientGroups.reduce((total, item) => total + item.ingredients.reduce((t, i) => i.weighted ? t + 1 : t, 0), 0) }} out of {{ recipe.ingredientGroups.reduce((total, item) => total + item.ingredients.length, 0) }} 
        ingredients are identified and included in nutritional value calculations. Recipe is for {{recipe.yieldText}} ({{recipe.yield}}) servings.
        <router-link to="/approach">Read how our recipe analysis works</router-link>.
        <!-- <v-chip color="red" dark>unknown</v-chip> ➜ 
        <v-chip color="orange" dark>parsed</v-chip> ➜ 
        <v-chip color="light-green" dark>matched</v-chip> ➜ 
        <v-chip color="green" dark>weighted</v-chip>  -->
      </v-card-text>
      <v-card-text v-if="recipe && recipe.origin && recipe.origin == 'animal-produced'">
        <v-alert color="warning" dark>Warning: this recipe might be vegetarian and not vegan!</v-alert>
      </v-card-text>
      <v-card-text v-if="recipe && recipe.origin && (recipe.origin == 'fish' || recipe.origin == 'meat')">
        <v-alert color="red" dark>Warning: this recipe might not be vegan and might contain fish or meat!</v-alert>
      </v-card-text>

      <div class="cursive text-center">
        <span class="d-inline-block pa-2" style="background-color: #ECEFF1;"> 
          Check to find recipes with selected ingredients.
        </span>
        <img src="/images/arrow-down.png" class=""/> 
      </div>
      <v-simple-table fixed-header v-if="recipe" >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Status</th>
              <th class="text-left">Text</th>
              <th class="text-left">

              </th>
              <th class="text-left pl-0">Ingredient</th>
              <!-- <th class="text-left">Quantity</th> -->
            </tr>
          </thead>
          <tbody>
            <template v-for="(group, i) in recipe.ingredientGroups">
              <tr :key="-1*(i+1)" v-if="group.name"><td colspan="6">{{group.name}}</td></tr>
              <tr v-for="(item, idx) in group.ingredients" :key="idx">
                <td>
                  <v-chip :color="getIngredientColor(item)" dark>{{ getIngredientStatus(item) }}</v-chip>
                </td>
                <td>{{ item.text }}</td>
                <td class="pr-0">
                  <v-checkbox v-if="item.foodId" @change="selectCheckbox(item.foodId)" v-model="selectedFoods" :value="item.foodId" :disabled="!enableCheckbox(item.foodId)"></v-checkbox>
                </td>
                <td class="pl-0">
                  <router-link v-if="item.foodId" tag="a" :to="{name: 'ingredient', params: {id: item.foodId}}">{{ item.parsed.food }}</router-link>
                  <!-- <span v-if="!item.foodId && item.parsed">{{ item.parsed.food }}</span> -->
                </td>
                <!-- <td>
                  <span v-if="item.weighted">{{ item.weighted.quantity }} {{ item.weighted.unit }}</span>
                  <i v-if="!item.weighted && item.parsed">{{ item.parsed.quantity }} {{ item.parsed.unit }}</i>
                </td> -->
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>

      <v-divider></v-divider>

      <!-- Instructions -->
      <v-card-title class="headline">Curious on how to prepare?</v-card-title>
      <v-card-text>
        <div class="text--primary">
          <v-btn color="primary" dark target="_blank" :href="recipe.url">Read preparation instructions &raquo;</v-btn>
          <!-- <v-btn v-if="sourceInfo" color="primary" dark target="_blank" :href="recipe.url">Read full recipe on {{sourceInfo.name}} &raquo;</v-btn> -->
          <div class="cursive mt-2 ml-4 d-flex ">
            <img src="/images/arrow.png" class="flex-shrink-1 mr-4"/> 
            <span class="flex-grow-1 align-self-center"> 
              <span v-if="sourceInfo">continue reading on {{sourceInfo.name}}</span>
              for more instructions, background stories, photos and/or preparation videos.
            </span>
          </div>
        </div>
      </v-card-text>
    </div>
<!--
    <v-card-title class="headline">Preparation Instructions</v-card-title>
       <v-list subheader>

      <div v-for="(group, i) in recipe.instructionGroups" :key="i">
      <v-subheader v-if="group.name">{{group.name }}</v-subheader>

      <v-list-item
        v-for="(instruction, i) in group.instructions" 
        :key="i"
      >
        <v-list-item-avatar>
          <v-chip color="red">#{{i+1}}</v-chip>
        </v-list-item-avatar>
        <v-list-item-content>
          <p> {{instruction.text}}</p>
        </v-list-item-content>

      </v-list-item>

      </div>
    </v-list> -->
    <v-divider></v-divider>
    <div v-if="showSimilar" id="tab-similar" class="tab-content" ref="tabSimilar">
      <v-card-title class="headline">Similar recipes <a name="similar">&nbsp;</a></v-card-title>
      <v-card-text>These recipes have similar ingredients.</v-card-text>
      <RecipeGrid :recipes="similarRecipes" class="px-4" />
      <v-divider></v-divider>
    </div>

    <!-- Nutrients -->
    <div v-if="showNutrients" id="tab-nutrients" class="tab-content" ref="tabNutrients">
      <v-row flex>
        <v-col cols="12">
          <v-card-title class="headline">Nutrients <a name="nutrients">&nbsp;</a></v-card-title>
          <v-card-text v-if="nutrientData && nutrientData.summary.servings">
            This recipe yields {{nutrientData.summary.servings}} servings of roughly {{nutrientData.summary.servingSize}} gram each.
            Nutriontional information is calculated per single serving.
          </v-card-text>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr class="">
                  <th class="text-left green white--text">Nutrient</th>
                  <th class="text-left green white--text">Value</th>
                  <th class="text-left green white--text">% DV</th>
                  <th class="text-left green white--text">Contributing Ingredients</th>
                </tr>
              </thead>
              <tbody>
                  <template v-for="(nutrients, category) in byCategory">
                  <tr :key="category" class="">
                    <td colspan="6">
                      <strong>{{category}}</strong>
                    </td>
                  </tr>
                  <template v-for="(nutrientUsage) in nutrients">
                  <tr :key="nutrientUsage.nutrientId">
                    <td><router-link tag="a" :to="{name: 'nutrient', params: {id: nutrientUsage.nutrientId}}">{{ nutrientUsage.nutrient.name }}</router-link></td>
                    <td>{{ nutrientUsage.value }} {{ nutrientUsage.unit }}</td>
                    <td>
                      <span v-if="nutrientUsage.nutrient.dailyIntake">
                        {{ Math.round((nutrientUsage.value / nutrientUsage.nutrient.dailyIntake) * 100)}}%
                      </span>
                    </td>
                    <td>
                      <span v-for="(item, i) in nutrientUsage.breakdown" :key="i">
                        <router-link v-if="item.foodId" tag="a" :to="{name: 'ingredient', params: {id: item.foodId}}">{{ item.food }}</router-link>
                        ({{Math.round(item.pct*100)}}%)
                        <strong v-if="i+1 < nutrientUsage.breakdown.length"> · </strong>
                      </span>
                    </td>
                  </tr>
                  </template>
                  </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
    <v-card-text>
      <NutrientCopyright />
    </v-card-text>  
      <div v-if="selectedFoods.length">
      </div>

    <v-bottom-sheet v-model="showCompareSheet" inset persistent hide-overlay>
      <v-sheet tile class="text-center" height="120px">
        <div class="py-3">Find recipes with selected ingredients (up to 3).</div>
        <v-btn color="primary" :to="{name: 'recipes', query: {foodId: selectedFoods}}">Find recipes</v-btn>
        <v-btn class="ml-6" text @click="showCompareSheet = !showCompareSheet">close</v-btn>
      </v-sheet>
    </v-bottom-sheet>
  </v-card>
</template>



<script>
import RecipeGrid from "@/components/RecipeGrid.vue";
import NutrientLabel from "@/components/NutrientLabel.vue";
import NutrientSummary from "@/components/NutrientSummary.vue";
import SourceInfo from "@/components/SourceInfo.vue";
import NutrientCopyright from "@/components/NutrientCopyright.vue";
import recipeService from "@/services/recipeService";
import sourcesInfo from '@/data/sources.json'
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "Recipe",
  components: {
    NutrientLabel,
    NutrientSummary,
    SourceInfo,
    NutrientCopyright,
    RecipeGrid,
  },
  props: {
    recipeId: String,
    msg: String
  },
  data() {
    return {
      recipe: null,
      source: null,
      nutrientData: null,
      similarRecipes: null,
      sourceInfo: null,
      isLoading: false,
      selectedTab: null,
      selectedFoods: [],
      showCompareSheet: false,
      tabInterceptorConfig: {
        handler: this.handleIntersect,
        options: {
          threshold: [0, 0.4, 0.5, 1.0] // raise events around these thresholds
        }
      }
    };
  },
  methods: {
        getIngredientColor(ingredient) {
          if (ingredient.origin && ingredient.origin != 'plant-based')
            return 'red';
          if (ingredient.weighted)
            return 'green';
          if (ingredient.foodId)
            return 'orange lighten-2';
          if (ingredient.parsed)
            return 'orange lighten-3';
        return 'orange';
    },
    getIngredientStatus(ingredient) {
        if (ingredient.origin && ingredient.origin != 'plant-based')
          return 'NOT VEGAN';
        if (ingredient.weighted)
            return 'included';
        if (ingredient.foodId)
            return 'not weighted';
        if (ingredient.parsed)
            return 'not matched';
        return 'unknown';
    },
    handleIntersect (entries, observer) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      //console.log('intersecting?', entries[0].target.id, 'pct:', entries[0].intersectionRatio,entries);
      if (entries && entries.length && entries[0].isIntersecting && entries[0].intersectionRatio >= 0.4){
        var tabs = document.querySelectorAll('.tab-content');
        var target = entries[0].target;
        var index = [].indexOf.call(tabs, target);
        //console.log('intersecting!', target.id, 'index', index);
        this.selectedTab = index; // bug: when similar is inserted later index doesn't work as expected
        var tab = document.querySelector('.v-tabs-bar .v-tab.v-tab--active');
        if (tab) {
          //tab.classList.remove('v-tab--active');
        }
      }
    },
    async loadRecipe(recipeId) {
      var response = await recipeService.get(recipeId);
      this.recipe = response.data;
      this.loadSource(this.recipe.source);
      var meta = {
        title: this.recipe.name,
        description: this.recipe.description,
        image: this.recipe.imgUrl,
      };
      EventBus.$emit('page-header-change', meta);
        // load data in correct (optimized) order... 
        // must also be alligned with tabs for autoscroll to work as expected!
      await this.loadSimilar(recipeId)
      await this.loadNutrients(recipeId)
      var schemaResonse = await recipeService.getSchema(recipeId)
      EventBus.$emit('page-schema-change', schemaResonse.data);


      var observer = new IntersectionObserver(this.handleIntersect, this.tabInterceptorConfig.options);
      this.$nextTick(() => {
        if (this.$refs.tabSimilar) {
          observer.observe(this.$refs.tabSimilar);
        }
        //observer = new IntersectionObserver(this.handleIntersect, this.tabInterceptorConfig.options);
        this.$nextTick(() => {
          observer.observe(this.$refs.tabNutrients);
        });
      });
    },
    async loadNutrients(recipeId) {
      var response = await recipeService.getNutrients(recipeId);
      this.nutrientData = response.data;
    },
    async loadSimilar(recipeId) {
      var response = await recipeService.getSimilar(recipeId);
      this.similarRecipes = response.data.data;
    },
    loadSource: function (source) {
      this.sourceInfo = sourcesInfo[source];
    },
    enableCheckbox(foodId) {
      return this.selectedFoods.length < 3 || this.selectedFoods.includes(foodId);
    },
    selectCheckbox(foodId) {
      console.log('check', foodId, this.selectedFoods.length)
      this.showCompareSheet = this.selectedFoods.includes(foodId) || this.selectedFoods.length >= 1;
      this.$nextTick(() => {
        // remove scroll lock added by component
        document.getElementsByTagName("html")[0].classList.remove('overflow-y-hidden'); 
      });
    },

  },
  computed: {
    byCategory(){
      return this.nutrientData.nutrients.reduce((acc, item) => {
        (acc[item.category] = acc[item.category] || []).push(item)
        return acc
      }, {});
    },
    showSimilar() { return this.recipe && this.similarRecipes && this.similarRecipes.length; },
    showNutrients() { return this.recipe && this.nutrientData; },
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadRecipe(to.params.id);
    next();
  },
  async mounted() {
    await this.loadRecipe(this.$route.params.id);
  }
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Indie+Flower&display=swap');

.cursive {
  font-family: 'Indie Flower', cursive;
  font-size: 120%;
}
.cursive img { max-height: 2em; }

.compare-callout {

}

.fixed-tabs-bar {
    position: sticky !important;
    position: -webkit-sticky;
    top: 60px !important;
    z-index: 4 !important;
}
.v-tabs-bar .v-tab {
  letter-spacing: unset !important;
  font-size: 75%;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 40px;
}

.recipe-rating { 
  background: rgba(0, 0, 0, .6); 
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  span {  }
}
.recipe-source { 
  background: rgba(0, 0, 0, .6); 
  padding: 8px;
  position: absolute;
  top: 0;
  right: 0;

  a {
    display: block;
    font-size: 1.2em;
    text-decoration: none;
    font-weight: 700;
  }

  i {
    float: right;
    font-size: 25px;
    padding: 9px 0 0 10px;
  }
}
</style>
