<template>
	<v-col cols="4" sm="3" md="2" lg="2" class="text-center">
		<div class="title">{{title}}</div>
		<v-progress-circular
			v-if="showProgress"
			:rotate="-90"
			:size="100"
			:width="15"
			:value="percentage"
			:color="color"
			>
			<div :class="avoid ? 'red--text text--darken-3' : 'green--text text--darken-3'">
				{{ percentage }}%
				<br/>DV 
			</div>
		</v-progress-circular>
		<div v-if="showProgress" class="subtitle">{{displayValue}}</div>
		<i v-if="!showProgress" class="fa fa-smile grey--text text--lighten-2" style="font-size:100px;"></i>
		<div v-if="!showProgress" class="subtitle">None</div>
	</v-col>
</template>

<script>

export default {
  name: "NutrientWidget",
  props: {
	percentage: Number,
	title: String,
	displayValue: String,
	avoid: Boolean
  },
  data() {
    return {
    };
  },
  computed: {
	showProgress: function () {
		return !this.avoid || this.percentage > 0;
	},
	color: function () {
		var lighten = '';
		if (this.percentage < 50) {
			lighten = 'lighten-1'
		}
		if (this.percentage < 33) {
			lighten = 'lighten-2'
		}
		if (this.percentage < 10) {
			//if (this.avoid) return 'orange';
			lighten = 'lighten-3'
		}
		if (this.avoid) {
			return 'red ' + lighten;
		}
		return 'green ' + lighten;
	}
  }
};
</script>

<style scoped>
.title { height: 1.5em; overflow: hidden; }
@media (max-width: 576px) { 
.title { font-size: 1.1rem !important; line-height: 1.25rem; }
}
</style>