<template>
    <v-container class="grey lighten-4 px-6 font-xs" v-if="source">
      <v-row align="center" class="">
        <!-- <v-col v-if="source.logo_url" class="shrink" :class="{'d-sm-break': $vuetify.breakpoint.xs || $vuetify.breakpoint.xs}">
			<img
				:src="source.logo_url"
				:alt="source.name"
				style="max-height:5em;"
			>
        </v-col> -->
        <v-col class="grow" :class="{'d-sm-break': $vuetify.breakpoint.xs || $vuetify.breakpoint.xs}">
			<h3 class="title">Source: {{ source.name }}</h3>
			{{ source.about }}
        </v-col>
        <v-col cols=12 class="shrink" :class="{'d-sm-break': $vuetify.breakpoint.xs || $vuetify.breakpoint.xs}">
			<v-btn outlined small color="blue" :to="'/recipes?q='+source.id">View recipes</v-btn>
			<v-btn text small color="blue" :href="url || source.url" target="_blank">Visit</v-btn>
        </v-col>		
      </v-row>		
    </v-container>
</template>

<script>

export default {
  name: "SourceInfo",
  props: {
	source: Object,
	url: String
  }
};
</script>

<style scoped>
	.d-sm-break {
			flex-basis: unset;
	}
</style>