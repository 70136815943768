<template>
    <v-card
      :to="{name: 'nutrient', params: {id: data.id}}"
      :color="categoryColor"
      >
      <v-img
        v-if="false && data.imageName"
        :src="imageCdnHost + '/' + data.imageName + '?h=200&amp;fit=crop'"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
      >
        <v-card-title v-text="data.name"></v-card-title>
      </v-img>
      <v-card-title :class="{'title-s' : $vuetify.breakpoint.xs }">
<!--         <v-avatar size="50px">
          <img
            v-if="data.imageName"
            :alt="data.name"
            :src="imageCdnHost + '/nutrients/thumbs/' + data.imageName"
          >
        </v-avatar>
 -->        {{data.name}}
      </v-card-title>
    </v-card>
</template>

<script>

export default {
  name: "NutrientCard",
  props: {
    nutrient: Object
  },
  data: function() {
    return {
      data: null,
      score: null,
      imageCdnHost: process.env.VUE_APP_IMG_CDN_HOST
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    categoryColor: function() {
      if (!this.data || !this.data.category.id) 
        return '';
      if (this.data.category.id == 'vitamins')
        return 'green lighten-4';
      if (this.data.category.id == 'minerals')
        return 'green lighten-3';
      return 'green lighten-2';
    }
  },
  watch: {
    nutrient: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler (val, oldVal) {
        //console.log('Prop changed: ', val, ' | was: ', oldVal)
        if (val.o) {
          // scored list
          this.data = val.o;
          this.score = val.d || val.s;
        }
        else {
          this.data = val;
        }
      }
    }
  }
};
</script>

<style scoped>
</style>