<template>
	<div v-if="model && model.summary">
		<div v-if="model && model.highs && model.highs.length">
			<v-card-title class="green--text darken-2 title">High in these essential nutrients</v-card-title>
			<v-card-text>This recipe contains significantly more of these nutrients than you normally find in a recipe.</v-card-text>
			<NutrientGrid :items="model.highs" class="mx-4" />
		</div>
		<v-card-title class="green--text darken-2 title">The good stuff (more is better)</v-card-title>
		<v-container>
		<v-row dense class="shrinkTitles">
			<NutrientWidget title="Vitamin A" :percentage="model.summary.vitaminAPctDV" :displayValue="model.summary.vitaminAContent" />
			<NutrientWidget title="Vitamin B6" :percentage="model.summary.vitaminB6PctDV" :displayValue="model.summary.vitaminB6Content" />
			<NutrientWidget title="Vitamin C" :percentage="model.summary.vitaminCPctDV" :displayValue="model.summary.vitaminCContent" />
			<!-- <NutrientWidget title="Vitamin D" :percentage="model.summary.vitaminDPctDV" :displayValue="model.summary.vitaminDContent" /> -->
			<v-col cols="12" sm="12" md="6" lg="6">
				<div class="title">Others</div>
				<NutrientLinearWidget title="Fibers" :percentage="model.summary.fiberPctDV" :displayValue="model.summary.fiberContent" />
				<NutrientLinearWidget title="Iron" :percentage="model.summary.ironPctDV" :displayValue="model.summary.ironContent" />
				<NutrientLinearWidget title="Calcium" :percentage="model.summary.calciumPctDV" :displayValue="model.summary.calciumContent" /> 
				<NutrientLinearWidget title="Omega 3" :percentage="model.summary.omega3PctDV" :displayValue="model.summary.omega3Content" />  
			</v-col>
			<!-- <NutrientWidget title="Fibers" :percentage="model.summary.fiberPctDV" :displayValue="model.summary.fiberContent" />
			<NutrientWidget title="Iron" :percentage="model.summary.ironPctDV" :displayValue="model.summary.ironContent" />
			<NutrientWidget title="Calcium" :percentage="model.summary.calciumPctDV" :displayValue="model.summary.calciumContent" /> -->
		</v-row>
		</v-container>
		<v-card-title class="red--text darken-2 title">Stuff to avoid (lower is better)</v-card-title>
		<v-container>
		<v-row dense class="shrinkTitles">
			<NutrientWidget avoid title="Sugar" :percentage="model.summary.sugarG" :displayValue="model.summary.sugarContent" />
			<NutrientWidget avoid title="Salt" :percentage="model.summary.sodiumPctDV" :displayValue="model.summary.sodiumContent" />
			<NutrientWidget avoid title="Saturated fats" :percentage="model.summary.saturatedFatPctDV" :displayValue="model.summary.saturatedFatContent" />
			<NutrientWidget avoid title="Trans fats" :percentage="model.summary.transFatG" :displayValue="model.summary.transFatContent" />
			<NutrientWidget avoid title="Cholesterol" :percentage="model.summary.cholesterolPctDV" :displayValue="model.summary.cholesterolContent" />
		</v-row>
		</v-container>
	</div>
</template>

<script>
import NutrientGrid from "@/components/NutrientGrid.vue";
import NutrientWidget from "@/components/NutrientWidget.vue";
import NutrientLinearWidget from "@/components/NutrientLinearWidget.vue";

export default {
  name: "NutrientSummary",
  props: {
    model: Object
  },
  components: {
    NutrientGrid,
    NutrientWidget,
    NutrientLinearWidget
  }
};
</script>
