<template>
  <section class="performance-facts" id="nutrition-facts">
		<header class="performance-facts__header">
			<h1 class="performance-facts__title">Nutrition Facts</h1>
			<p>Serving Size 1 portion (about {{summary.servingSize}}g)
				<p>Servings Per Recipe {{summary.servings}}</p>
		</header>
		<table class="performance-facts__table" cellspacing=0>
			<thead>
				<tr>
					<th colspan="3" class="small-info">
						<strong>Amount Per Serving</strong>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<th colspan="2"><b>Calories</b> {{summary.calories}}</th>
					<td>Calories from Fat {{summary.caloriesFromFat}}</td>
				</tr>
				<tr class="thick-row">
					<td colspan="3" class="small-info">
						<b>% Daily Value*</b>
					</td>
				</tr>
				<tr>
					<th colspan="2"><b>Total Fat</b> {{summary.fatG}}g</th>
					<td><b>{{summary.fatPctDV}}%</b></td>
				</tr>
				<tr>
					<td class="blank-cell"></td>
					<th>Saturated Fat {{summary.saturatedFatG}}g</th>
					<td><b>{{summary.saturatedFatPctDV}}%</b></td>
				</tr>
				<tr>
					<td class="blank-cell">&nbsp;</td>
					<th><i>Trans</i> Fat {{summary.transFatG}}g</th>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<th colspan="2"><b>Cholesterol</b> {{summary.cholesterolMg}}mg</th>
					<td><b>{{summary.cholesterolPctDV}}%</b></td>
				</tr>
				<tr>
					<th colspan="2"><b>Sodium</b> {{summary.sodiumMg}}mg</th>
					<td><b>{{summary.sodiumPctDV}}%</b></td>
				</tr>
				<tr>
					<th colspan="2"><b>Total Carbohydrate</b> {{summary.carbohydrateG}}g</th>
					<td><b>{{summary.carbohydratePctDV}}%</b></td>
				</tr>
				<tr>
					<td class="blank-cell"></td>
					<th>Dietary Fiber {{summary.fiberG}}g</th>
					<td><b>{{summary.fiberPctDV}}%</b></td>
				</tr>
				<tr>
					<td class="blank-cell"></td>
					<th>Sugars {{summary.sugarG}}g</th>
					<td>&nbsp;</td>
				</tr>
				<tr class="thick-end">
					<th colspan="2"><b>Protein</b> {{summary.proteinG}}g</th>
					<td>&nbsp;</td>
				</tr>
				<tr>
				<tr class="xthick-row">
					<th colspan="2">Vitamin A</th>
					<td>{{summary.vitaminAPctDV}}%</td>
				</tr>
				<tr class="">
					<th colspan="2">Vitamin C</th>
					<td>{{summary.vitaminCPctDV}}%</td>
				</tr>
				<tr class="">
					<th colspan="2">Calcium</th>
					<td>{{summary.calciumPctDV}}%</td>
				</tr>
				<tr class="">
					<th colspan="2">Iron</th>
					<td>{{summary.ironPctDV}}%</td>
				</tr>

			</tbody>
		</table>

		<p class="small-info">* Percent Daily Values are based on a 2,000 calorie diet. Your daily values may be higher
			or lower depending on your calorie needs:</p>

		<table class="performance-facts__table performance-facts__table--small small-info">
			<thead>
				<tr>
					<td colspan="2"></td>
					<th>Calories:</th>
					<th>2,000</th>
					<th>2,500</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<th colspan="2">Total Fat</th>
					<td>Less than</td>
					<td>65g</td>
					<td>80g</td>
				</tr>
				<tr>
					<td class="blank-cell"></td>
					<th>Saturated Fat</th>
					<td>Less than</td>
					<td>20g</td>
					<td>25g</td>
				</tr>
				<tr>
					<th colspan="2">Cholesterol</th>
					<td>Less than</td>
					<td>300mg</td>
					<td>300 mg</td>
				</tr>
				<tr>
					<th colspan="2">Sodium</th>
					<td>Less than</td>
					<td>2,400mg</td>
					<td>2,400mg</td>
				</tr>
				<tr>
					<th colspan="3">Total Carbohydrate</th>
					<td>300g</td>
					<td>375g</td>
				</tr>
				<tr>
					<td class="blank-cell"></td>
					<th colspan="2">Dietary Fiber</th>
					<td>25g</td>
					<td>30g</td>
				</tr>
			</tbody>
		</table>

		<p class="small-info">
			Calories per gram:
		</p>
		<p class="small-info text-center">
			Fat 9
			&bull;
			Carbohydrate 4
			&bull;
			Protein 4
		</p>

	</section>
</template>

<script>

export default {
  name: "NutrientLabel",
  props: {
    summary: Object
  }
};
</script>

<style scoped>

			.performance-facts {
				border: 1px solid black;
				max-width: 300px;
				font-size: 12px;
				padding: 0.5rem;
			}

			.performance-facts__title {
				font-weight: 800;
				font-size: 2rem;
				margin: 0 0 0.25rem 0;
			}

			.performance-facts__header {
				border-bottom: 10px solid black;
				padding: 0 0 0.25rem 0;
				margin: 0 0 0.5rem 0;
			}

			.performance-facts__header p {
				margin: 0;
			}

			.performance-facts__table {
				width: 100%;
			}

			.performance-facts__table thead tr th,
			.performance-facts__table thead tr td {
				border-collapse: collapse;
				border: 0;
			}

			.performance-facts__table th,
			.performance-facts__table td {
				font-weight: normal;
				text-align: left;
				padding: 0.25rem 0;
				border-top: 1px solid black;
				white-space: nowrap;
			}

			.performance-facts__table td:last-child {
				text-align: right;
			}

			.blank-cell {
				width: 1rem;
				border-top: 0 !important;
			}

			.xthick-row th,
			.xthick-row td {
				border-top-width: 10px;
			}

			.thick-row th,
			.thick-row td {
				border-top-width: 5px;
			}

			.small-info {
				font-size: 0.7rem;
			}

			.performance-facts__table--small {
				border-bottom: 1px solid rgb(153, 153, 153);
				margin: 0 0 0.5rem 0;
			}

			.performance-facts__table--grid td:last-child::before {
				content: "•";
				font-weight: bold;
				margin: 0 0.25rem 0 0;
			}

			.text-center {
				text-align: center;
			}

			.thick-end {
				border-bottom: 10px solid black;
			}

			.thin-end {
				border-bottom: 1px solid black;
			}
</style>