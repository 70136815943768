<template>
    <p>Source: U.S. Department of Agriculture, Agricultural Research Service. FoodData Central, 2019. fdc.nal.usda.gov</p>
</template>


<script>

export default {
  name: "NutrientCopyright",
  props: {
  },
  data: function() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style scoped>
</style>