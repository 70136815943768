<template>
	<div class="mb-3">
		<div class="d-flex justify-space-between">
			<div class="">{{title}}</div>
			<div class="">{{percentage}}% ({{displayValue}})</div>
		</div>
		<v-progress-linear
			v-if="showProgress"
			:height="5"
			:value="percentage"
			:color="color"
			>
		</v-progress-linear>
	</div>
</template>

<script>

export default {
  name: "NutrientLinearWidget",
  props: {
	percentage: Number,
	title: String,
	displayValue: String,
	avoid: Boolean
  },
  data() {
    return {
    };
  },
  computed: {
	showProgress: function () {
		return !this.avoid || this.percentage > 0;
	},
	color: function () {
		var lighten = '';
		if (this.percentage < 50) {
			lighten = 'lighten-1'
		}
		if (this.percentage < 33) {
			lighten = 'lighten-2'
		}
		if (this.percentage < 10) {
			//if (this.avoid) return 'orange';
			lighten = 'lighten-3'
		}
		if (this.avoid) {
			return 'red ' + lighten;
		}
		return 'green ' + lighten;
	}
  }
};
</script>

<style scoped>
	.v-progress-linear { top: 0; }
</style>